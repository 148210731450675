<template>
  <v-container
      :class="ShowMainPage === 'Y' ? 'pa-0 custom-bg' : 'pa-0'"
      fluid
      fill-height>
    <v-container>
      <v-row>
        <v-col cols="12">
          <slot />
        </v-col>
        <v-col cols="12" class="mb-0 pb-0">
          <span class="display-3"><strong>{{ $t('MeeTeeMeeNgern1.product') }}</strong></span>
          <v-divider></v-divider>
        </v-col>
        <product-feed-card
          v-for="(Product, i) in paginatedProduct"
          :key="Product.product_skey"
          :size="layoutProducgt[i]"
          :value="Product"
          v-show="i===0"
        />
        <!-- Golf  add v-show="i===0" เพื่อไม่แสดง ขายฝาก -->
      </v-row>

      <v-row align="center">
        <v-col cols="3">
          <base-btn
            v-if="page !== 1"
            class="ml-0"
            square
            title="Previous page"
            @click="page--"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </base-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
  import {
    mapState,
  } from 'vuex'

  export default {
    name: 'Products',
    props: {
      limitShow: {
        type: Number,
        required: false,
      },
      ShowMainPage: {
        type: String,
        required: false,
      },
    },
    data: () => ({
      layout: [3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
      layoutProducgt: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      page: 1,
      product: [],
      /* product: require('@/data/product.json'), */
    }),
    async created () {
      await this.getProductList(localStorage.getItem('language'))
    },
    components: {
      ProductFeedCard: () => import('@/components/ProductFeedCard'),
    },
    computed: {
      ...mapState(['articles']),
      pages () {
        return Math.ceil(this.articles.length / 11)
      },
      paginatedArticles () {
        const start = (this.page - 1) * 11
        const stop = this.page * 11

        return this.articles.slice(start, stop)
      },
      paginatedPromotion () {
        const start = (this.page - 1) * 11
        const stop = this.page * 11

        return this.promotion.slice(start, stop)
      },
      paginatedProduct () {
        const start = (this.page - 1) * 11
        const stop = this.page * 11

        return this.product.slice(start, stop)
      },
    },

    watch: {
      page () {
        window.scrollTo(0, 0)
      },
    },
    methods: {
      selectLanguage (language) {
        console.log(language, 'language componnet Register')
        this.getProductList(language)
      },
      async getProductList (language) {
        var lang = 1
        if (language === 'en') {
          lang = 2
        } else {
          lang = 1
        }
        lang = 'th'
        await this.axios.post(process.env.VUE_APP_API + '/product/retrieveProductList' + process.env.VUE_APP_DATABASE_TYPE, {
          lang: lang
        })
          .then(response => {
            this.product = response.data.data
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
    }
  }
</script>
<style scoped>
  .custom-bg {
    background: rgb(242, 245, 247) !important;
    /* background: url('../../assets/bg/bg1.jpg');
    background-size: cover; */
  }
</style>